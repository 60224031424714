#poster {
  background-color: #ffffff;
}

#log_img {
  width: 602px;
  height: 500px;
  padding-left: 10px;
}

body {
  background-color: #fef7e9;
}

.divimg {
  /* background: url(); */
}

.login_main {
  height: 100vh;
}

#main_container > div:first-child {
  height: 100vh;
}
.login-btn {
  border: 0;
  background-color: #124780;
  width: 100%;
  color: #ffffff;
  font-weight: bold;
}

a {
  text-decoration: none;
  color: #2b3444;
}

.login-title {
  color: #2b3444;
}

input::placeholder {
  color: #afb0b2;
}

#form_part {
  background-color: #fff;
  height: 100vh;
}

#rtside {
  height: 500px;
  width: 90%;
}
.input-group-text {
  height: 38px;
}
@media (min-width: 992px) {
  #inp_container {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image-logo {
  width: 60%;
  height: auto;
}
.image-logo1 {
  width: 60%;
  height: auto;
}
.linkColor {
  color: initial;
  text-decoration: none;
}
.linkColor:hover {
  color: var(--tm-bgcolor2);
  text-decoration: underline;
}
