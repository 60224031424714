.projectTable {
  overflow-y: auto;
  max-height: 360px;
}
.wholecard {
  width: 20rem;
  border: 1px solid #eeeeee;
  background: '#FFFFFF';
  border-radius: 10px;
  box-shadow: 10px 10px 20px 0px rgba(252, 250, 250, 0.918);
}

.between {
  justify-content: space-between;
  align-items: start;
}
.imgctrl > div:first-child {
  max-height: 50px !important;
  /* border: 1px solid ; */
}
.imgctrl img {
  object-fit: contain !important;
}

.betweenCenter {
  justify-content: space-between;
  align-items: center;
}

.ligthcolor {
  color: #82858f;
}

.darkblue {
  color: #134780;
}

.timeformate {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hrsformate {
  display: flex;
  align-items: top;
  font-size: 12px;
}

.buttonborder {
  border: 0;
}

.title-font-size {
  font-size: 16px;
}
.projectCard {
  border: 1px solid var(--tm-bgcolor4) !important;
}
.projectCard:hover {
  scale: 1.02;
  transition-duration: 80ms;
  background-color: var(--tm-bgcolor11) !important;
  box-shadow: 0px 5px 10px var(--tm-bgcolor4);
}
.disable > div > div:not(:first-child) {
  opacity: 0.4 !important;
}
.TotalHours {
  background-color: #ffffff !important;
  height: 90% !important;
  min-height: 76px;
}

.viewInformation {
  background-color: #ffffff !important;
}

.viewTable {
  background-color: #ffffff !important;
  height: 320px;
  overflow-y: auto;
}
.color1 {
  color: #5f6271 !important;
}
.color2 {
  color: #4b7e69 !important;
}
.color3 {
  color: #556ee5 !important;
}
.color4 {
  color: #f8a013 !important;
}
.color5 {
  color: #7a5ac8 !important;
}
.color6 {
  color: #134780 !important;
}
.color7 {
  color: #515467 !important;
}
.color8 {
  color: #2b3444 !important;
}
.arrow {
  cursor: pointer !important;
  margin: 0px !important;
  color: #5f6271 !important;
  background: white !important;
  border-radius: 3px !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}
.disable > td:not(:last-child) {
  /* background-color: #f1f1f1 !important; */
  opacity: 0.45 !important;
  /* color: #cac8c8 !important; */
}
.line {
  background-color: #f8a013 !important;
}
.tablenowrap {
  position: sticky;
  top: 0;
}
.historyScrolable {
  max-height: 280px;
  max-width: 500px;
  width: 100%;
  overflow-y: auto;
}

.background4 {
  background-color: #ffff;
  border-radius: 10px;
}
.fontsizeAdd {
  font-size: 17px;
  text-align: center;
}
.addedValues {
  border: 1px solid #d4e9fe;
  background-color: #d4e9fe;
  width: 88px !important;
  border-radius: 10px 1px 1px 10px;
}
.TotalValue {
  border: 1px solid #d4e9fe;
  background-color: #ffefd2;
  width: 88px !important;
  border-radius: 10px 1px 1px 10px;
}
.Reason {
  border: 1px solid #d4e9fe;
  background-color: #b67d14;

  border-radius: 10px 1px 1px 10px;
}
.overallDiv {
  text-align: -webkit-center;
}
.forReasonlatter {
  font-family: 'Times New Roman', Times, serif;
  text-align: start !important ;
  width: 135px !important;
  text-align: start !important;
}
.paddingforForm {
  padding-top: 34px !important;
}
.headerColor {
  background-color: #dbe2e6;
  width: 146px;
}
.headerColorEnd {
  background-color: #dbe2e6;
  width: 156px;
  margin-top: 3px !important;
  z-index: 3 !important;
}

.headerColorEst {
  background-color: #dbe2e6;
  width: 156px;
  margin-top: 3px !important;
  z-index: 5;
}
.headerColorCreated {
  background-color: #dbe2e6;
  width: 156px;
}
.headerColorReason {
  background-color: #dbe2e6;
  width: 430px;
  margin-top: 3px !important;
}
.fourcardcolor {
  padding: 3px;
}
.tablehieght {
  font-weight: bold;
  font-size: 14px;
}
.colorforstartDate {
  color: #5f6271 !important;
}
.tablecreated {
  font-size: 14px;
}
.tablecreatedReason {
  width: 320px;
  overflow: visible;
}
