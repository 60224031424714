.MuiAccordionSummary-content {
  padding: 10px 8px !important;
  margin: 0 !important;
  border: 1px solid #dadcdf !important;
  border-radius: 0.15rem;
}
.MuiButtonBase-root .MuiAccordionSummary-root {
  padding: 0px !important;
}
.MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}
.MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
  background-color: #fff !important;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0px !important;
}
.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0px !important;
}
.MuiAccordionSummary-content:not(.Mui-expanded) .carethandler {
  /* background-color: red !important; */
  transform: rotate(0deg) !important;
  transition-duration: 500ms;
}
.MuiAccordionSummary-content.Mui-expanded .carethandler {
  /* background-color: blue !important; */
  transform: rotate(-180deg) !important;
  transition-duration: 500ms;
}

.MuiOutlinedInput-input {
  padding: 8px !important;
}
