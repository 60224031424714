.card {
  position: relative;
  top: 0px;
}
.card::before {
  content: '';
  min-height: 60%;
  max-height: 85%;
  position: absolute;
  border: 2px solid var(--tm-bgcolor2);
  top: 20%;
  left: -2px;
}
