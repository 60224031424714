.disable > td:not(:last-child) {
  /* background-color: #f1f1f1 !important; */
  opacity: 0.45 !important;
  /* color: #cac8c8 !important; */
}

/* style={{
  width: '55px',
  maxWidth: '55px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
}} */