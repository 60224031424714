.attendanceTable table > thead > tr > th {
  min-width: 80px !important;
  padding-right: 5px;
  padding-left: 5px;
  /* border: 1px solid red !important; */
}

.attendanceTable table > tbody > tr > td {
  padding: 3px 5px;
  /* text-align: center; */
  vertical-align: middle;
}

.attendanceTable table > thead > tr > th:nth-child(-n + 6):not(:first-child) {
  min-width: 150px !important;
  width: 100% !important;
}
.attendanceTable table > thead > tr > th:last-child {
  min-width: 200px !important;
}

@media screen and (max-width: 768px) {
  .tmfiterwidthres {
    width: 100% !important;
  }
}
