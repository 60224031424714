.edit-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.profile-icon:hover .edit-icon {
  opacity: 1;
}

.btn-space {
  margin-right: 10px;
}

.add-user {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--bs-heading-color);
}

.add-user-container {
  border: 2px solid #d1d1d1;
  display: inline-block;
}

.arrow-icon {
  cursor: pointer;
  margin: 0px;
  color: #5f6271;
  background: white;
  border-radius: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.month-placehold {
  font-size: 14px;
  color: #82858f;
}
.checkbox-address {
  font-size: 10px;
  margin-right: 5px;
}

.checkbox-address .ant-checkbox-inner {
  width: 16px !important;
  height: 16px !important;
}

.title-style {
  color: #134780;
  font-size: large;
  font-weight: 500;
  padding-bottom: 10px;
}
.forpaid {
  margin-top: 30px;
}
.tablestyles {
  width: 130px;
  height: 20px;

  background-color: white !important;
}
/* .documentUpload{
  border: 1px solid red;
}
.documentUpload + .ant-upload-list-item {
  display: none !important;
} */
.defaultColor {
  color: #134780;
  margin-right: 0 !important;
}
.defaultColor:hover {
  color: #f8a013;
}

:where(.css-dev-only-do-not-override-1ffe8a6).ant-btn:not(.ant-btn-icon-only)
  > .ant-btn-icon:not(:last-child) {
  margin-inline-end: 0px !important;
}
/* .ant-btn-icon {
  margin-inline-end: 0px !important;
} */

/* .plusIcon {
  margin-right: 0 !important;
} */

.plusIcon-btn .anticon {
  margin-right: 0 !important;
}
.Header-Bold {
  color: #82858f;
}
