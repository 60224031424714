.overflow {
  overflow-x: hidden !important;
}
.forboredr {
  border: 1px solid #c1c1c1 !important;
  border-radius: 5px !important;
  /* height: 40px !important; */
  width: 100% !important;
  padding: 8px 0px !important;
}
.fontcolor {
  color: #000104 !important ;
  font-size: 13px !important;
}
