.memberbackground {
  background-color: #ebf3f0;
  width: 120px;
  margin-left: 10px;
  padding: 1px;
}

.member {
  color: #4b7e69;
}

.FiEdit {
  float: inline-end;

  border: none !important;
}
.FiEdit :hover {
  color: #f8a013;
}

.moveRight .btn {
  border: none !important;
  margin-bottom: 2px;
}

.teen {
  background-color: #dfe9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #292c40;
  font-size: x-large;
  height: 32px;
  width: auto;
  /* margin-left: 110px; */
  /* margin-top: 15px; */
}
.twelve {
  background-color: #ccdfdf;
  width: 60px;
  height: 26px;
  text-align: center;
  padding-top: 1px;

  color: #292c40;
}
.ModalHeader {
  border: 0;
}
.bodymodal {
  padding: 0;
}
.App {
  height: 300px;
}

.ant-layout {
  padding: 0 !important;
}
.start {
  color: #82858f;
  font-size: 14px;
  font-family: 'lato';
}

.yearSelect {
  padding-left: 20px;
}
.profilename {
  width: 20px;
}
.testmargin {
  margin-top: 40px;
}
.forPhone {
  margin-top: 14px;
}

.whiteBackground {
  background-color: white;
}

.activeBackground {
  background-color: #007bff; /* Bootstrap active color */
  color: white; /* Text color to ensure readability */
}
.tr:nth-child(even) td {
  background-color: #f3f3f3;
}
@media (min-width: 768px) and (max-width: 2560px) {
  .row {
    height: 35px;
    padding-bottom: 2px;
  }
  .start {
    padding-left: 23px;

    text-wrap: nowrap;
  }
  .end {
    /* width: 300px; */
    /* padding-left: 58px; */

    color: #292c40;
    font-family: 'lato';
    font-weight: 400;
    font-size: 14px;
  }

  .holy {
    width: 750px;
  }
}
@media screen and (max-width: 768px) {
  .start {
    padding-left: 5px;
  }
}
