.form-control {
  border-radius: 3px !important;
}
.form-control:focus {
  border-color: var(--tm-bgcolor2) !important;
  box-shadow: none !important;
}
/* .accordion-button:not(.collapsed) {
  background-color: #f8a0132d !important;
} */

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--tm-bgcolor4) !important;
  color: black !important;
}
.dropdown-item:hover {
  color: var(--tm-bgcolor2) !important;
}
.dropPointer > .dropdown-item{
  
  cursor: default !important;
}