.addModule {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.input {
  width: 300px;
}
.btn-space {
  margin-right: 10px;
}

.totalhourscolor {
  background-color: #c8d4e7;
  border-radius: 6px;
}

.historyScrolable {
  max-height: 280px;
  max-width: 500px;
  width: 100%;
  overflow-y: auto;
}
.fourcardcolor {
  padding: 10px;
}

.background4 {
  background-color: #eaeef2;
  border-radius: 10px;
}
.fontsizeAdd {
  font-size: 17px;
  text-align: center;
}
.fontcolor {
  color: #888b99;
}
.tablehieght {
  font-size: 14px;
  font-weight: bold;
}
.tablecreated {
  font-size: 14px;
}
