@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* overall setup */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Lato', sans-serif !important;
  /* font-weight: 400 !important; */
  /* font-style: normal !important; */
}

#root,
html,
body {
  height: 100%;
}

:root {
  --tm-bgcolor1: #ffffff;
  --tm-bgcolor2: #1b67b3;
  /* --tm-bgcolor1: #163658; */
  /* --tm-bgcolor2: #f8a013; */
  --tm-bgcolor3: #f4f6f8;
  --tm-bgcolor4: #dbe2e6;
  --tm-bgcolor5: #134780;
  --tm-bgcolor6: #eaeef2;
  --tm-bgcolor7: #f5f8ff;
  --tm-bgcolor8: #eeeeee;
  --tm-bgcolor9: #eafff2;
  --tm-bgcolor10: #4b7e69;
  --tm-bgcolor11: #f2f8fd; /* hover color */
  /* --tm-bgcolor11: #fff9ef;  */
  --tm-bgcolor12: #82858f;
  --tm-bgcolor13: #fff1db;
  --tm-bgcolor14: #ffecce;
  --tm-bgcolor15: #ddedff;
  --tm-bgcolor16: #7bd8df;
  --tm-bgcolor17: #b6d56c;
  --tm-bgcolor18: #f5a88f;
}

/* button styles for all bootstrap button */

.btn {
  border-radius: 3px !important;
}
.tm_button1 {
  background-color: var(--tm-bgcolor1) !important;
  color: #fff !important;
}
.tm_button2 {
  background-color: var(--tm-bgcolor2) !important;
  color: #fff !important;
}
.tm_button3 {
  background-color: var(--tm-bgcolor3) !important;
  color: var(--tm-bgcolor1) !important;
  border: 1px solid var(--tm-bgcolor1) !important;
}
.tm_button4 {
  background-color: var(--tm-bgcolor7) !important;
  color: var(--tm-bgcolor5) !important;
}
.tm_button5 {
  background-color: var(--tm-bgcolor8) !important;
  color: var(--tm-bgcolor1) !important;
}

/* .tm_button5:active{
  background-color: red !important;
} */

/* width height 100% classes for commom areas */

.width_height {
  width: 100% !important;
  height: 100% !important;
}
.width {
  width: 100% !important;
}
.height {
  height: 100% !important;
}

/* commom background colors  */

.layout_container {
  background-color: var(--tm-bgcolor3);
}
.header_bgcolor {
  background-color: var(--tm-bgcolor6) !important;
}
.statusColor {
  background-color: var(--tm-bgcolor9) !important;
}
/* commom font & icons colors  */
.primaryColor {
  color: var(--tm-bgcolor2) !important;
}

.statusSuccess {
  color: var(--tm-bgcolor10) !important;
}
.supported_text {
  color: var(--tm-bgcolor5) !important;
}
.card_text {
  color: var(--tm-bgcolor12) !important;
}
.mandatory {
  color: red !important;
}
.primaryColor {
  color: var(--tm-bgcolor5) !important;
}
.status-completed {
  color: green;
}
.status-inprogress {
  color: orange;
}
.status-overdue {
  color: red;
}
.status-uninitiated {
  color: black;
}

/* table overrides  */

table > thead > tr > th {
  background-color: var(--tm-bgcolor4) !important;
  font-size: 13px !important;
  vertical-align: middle;
  text-wrap: nowrap !important;
}
table > tbody > tr > td {
  max-width: 170px;
  overflow: hidden;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.firstLetter::first-letter {
  text-transform: capitalize;
}

/* header for all cards  */
.module_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.tmPointer {
  cursor: pointer !important;
}
.tmCursorDefault {
  cursor: default !important;
}
.antdBtn > button:last-child {
  margin-left: 8px !important;
}

/* scrollbar designs pattern */
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px !important;
  box-shadow: inset 0 0 3px grey;
  margin-left: 6px !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: rgb(196, 196, 196);
}

.arrow.rotateArrow {
  transform: rotate(180deg) !important;
  transition-duration: 500ms !important;
}
.arrow {
  transform: rotate(0deg) !important;
  transition-duration: 500ms !important;
}

.tmTextOverflow {
  width: 96%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arrow-icon {
  cursor: pointer;
  margin: 3px;
  color: #5f6271;
}

.modal-header .btn-close:focus {
  box-shadow: 1px 1px 6px var(--tm-bgcolor2), -1px -1px 6px var(--tm-bgcolor2) !important;
}
/* Edit, Delete icon */
.icon-hover :hover {
  color: var(--tm-bgcolor2);
}
.icon-hover:hover span {
  color: var(--tm-bgcolor2);
  /* border: 1px solid red !important; */
}
.icon-btn .btn {
  border: none !important;
}
.tr:nth-child(even) td {
  background-color: #f3f3f3;
}
.d-fec {
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
}
.d-fcc {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.d-fsc {
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
}
.d-fbc {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.ant-badge .ant-badge-count {
  background-color: var(--tm-bgcolor2) !important;
}
:where(.css-dev-only-do-not-override-1ffe8a6).ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-error,
:where(.css-dev-only-do-not-override-1ffe8a6).ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-error {
  border: none;
}
.tmoverlay {
  position: absolute !important;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: rgba(211, 211, 211, 0.527);
  top: 0px;
  left: 0px;
}
.hoverTr {
  cursor: pointer !important;
}
.hoverTr:hover > td {
  background-color: var(--tm-bgcolor11) !important;
}
.emailtable table {
  width: 100% !important;
}
.emailtable table > tbody > tr > td {
  /* max-width: 170px;
  overflow: hidden;
  vertical-align: middle; */
  white-space: wrap !important;
  /* text-overflow: ellipsis; */
}
.ant-image-mask {
  border-radius: 50px !important;
}
.ant-drawer-body {
  overflow-x: hidden !important;
}
.ant-tabs-nav {
 
  margin: 0px !important;
  margin-left: 7px !important;

}
