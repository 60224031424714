.card {
  width: 20rem;
  border: 1px solid #cf2020;
  background: '#FFFFFF';
  border-radius: 10px;
  box-shadow: 10px 10px 20px 0px rgba(252, 250, 250, 0.918);
}
.bol {
  background-color: #cf2020;
  border: 1px solid red;
}

.font {
  font: normal normal medium 14px/32px 'Lato', sans-serif;
  color: #5f6271 !important;
}
.forproject {
  background-color: #dbe5ef;
  font-size: 15px;
}
@media screen and (min-width: 768px) {
  .mediumscreen {
    height: 400px;
  }
  .forproject {
    background-color: #dbe5ef;
    width: 40%;
  }
}
.resprojecthistorytab {
  overflow-y: auto !important;
  background-color: white;
}
.modelhistorytab {
  overflow-y: auto !important;
  background-color: white;
  margin-left: 3px !important;
}
.tableheadfixed {
  position: sticky;
  top: 0;
}
.backgroundcolor23 {
  background-color: white;
}

/* Styling the vertical scrollbar */
.modelhistorytab::-webkit-scrollbar {
  width: 8px;
  height: 8px; /* Make the vertical scrollbar narrower */
}
.PHFont {
  font-size: 15px;
}
.arrow {
  cursor: pointer !important;
  margin: 0px !important;
  color: #5f6271 !important;
  background: white !important;
  border-radius: 3px !important;

  margin-bottom: 3px !important;
}
