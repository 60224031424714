.reportShow {
  width: 100px;
  background-color: #e5e5e5;
}
.forFirstname {
  max-width: fit-content;
  background-color: #e5e5e5;
}
.forSecond {
  background-color: #e5e5e5;
}
.buttonWidth {
  width: 110px;
}
.CompetencyCard {
  background-color: #ffffff;
  height: 100;
}
.CompetencyCard2 {
  background-color: #ffffff;
  height: 100%;
}
.CompetencySpace {
  padding-top: 13px;
  color: #383c51;
  font-family: "Lato";
  font-size: 14px;
}
