.addLogTable > div > table > thead > tr {
  color: red !important;
  /* text-align: end !important; */
}
.billable {
  background-color: #22b579;
  padding: 1px 6px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
}
.nonBillable {
  background-color: #a0a1a8;
  padding: 1px 6px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
}
.checkIcon {
  color: #0e7e50 !important;
}
.table > :not(caption) > * > * {
  color: #0e7e50 !important;
}
.secondApprover {
  /* background-color: #f8a013; */
  background-color: #fff9ef;
  color: #c4903f;
}
.hoverTr {
  cursor: pointer !important;
}
.hoverTr:hover > td {
  background-color: var(--tm-bgcolor11) !important;
}
.holidayGreen > td {
  color: var(--tm-bgcolor10) !important;
  font-weight: bold;
}
.permission > td:nth-child(1),
.permission > td:nth-child(2),
.permission > td:nth-child(3),
.permission > td:nth-child(4),
.permission > td:nth-child(5) {
  background-color: var(--tm-bgcolor13) !important;
  font-weight: 500;
}
/* .permission > td:not(:last-child,:nth-last-child(2)) {
  background-color: var(--tm-bgcolor13) !important;
  font-weight: 500;
} */

.leaveRed > td:nth-child(1),
.leaveRed > td:nth-child(2),
.leaveRed > td:nth-child(3),
.leaveRed > td:nth-child(4),
.leaveRed > td:nth-child(5) {
  color: #bf2e2e !important;
  font-weight: bold;
}

/* .leaveRed > td:not(:last-child,:nth-last-child(2)) {
  color: #bf2e2e !important;
  font-weight: bold;
} */
.tableDisable table tbody td {
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}
.NormalBtn {
  cursor: default !important;
}
.NormalBtn:focus-visible {
  outline: 0px solid #ffffff00 !important;
  outline-offset: none !important;
}
.addcardicon {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icontranslate {
  transform: translateY(-3px);
  padding-top: 2px;
}

.circleRadius {
  border-radius: 50%;
  background-color: var(--tm-bgcolor15);
  padding: 8px;
  line-height: 13px;
}
.childcolor {
  color: #82858f !important;
}

.approveGreen > span:first-child > input:checked + span {
  border-color: green !important;
  background-color: green !important;
}
.rejectRed > span:first-child > input:checked + span {
  border-color: red !important;
  background-color: red !important;
}
/* tmtableimage */
.words {
  /* background-color: rgb(107, 91, 91); */
  border: solid 1px transparent;
  border-radius: 50%;

  min-width: 25px !important;
  min-height: 25px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 11px;
}
.imageCircleShape {
  border-radius: 50%;

  max-width: 26px !important;
  max-height: 26px !important;
}
.antimagemask {
  border-radius: 50px !important;
}
.ant-image-mask {
  border-radius: 50px !important;
}
@media screen and (max-width: 767px) {
  .sizeforfont {
    font-size: 12px !important;
  }
  .flexsmall {
    display: flex !important;
  }
  .sizefortotal {
    font-size: 15px !important;
    font-family: 'Times New Roman', Times, serif;
  }
}
