.bgcolor {
  background-color: var(--tm-bgcolor1);
  height: 100vh;
}
.logo {
  height: 60px;
}
.menu_list {
  list-style-type: none;
  padding: 0;
}
.sideBarScroller::-webkit-scrollbar {
  width: 5px !important;
}
/* Track */
.sideBarScroller::-webkit-scrollbar-track {
  border-radius: 10px !important;
  box-shadow: inset 0 0 3px grey;
  margin-left: 6px !important;
}
/* Handle */
.sideBarScroller::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: var(--tm-bgcolor13);
}
.sideBarScroller {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 64px);
}

/* for sider bar responsive*/
@media (max-width: 1024px) {
  .sideBar {
    position: absolute !important;
    top: 0px;
    height: 100% !important;
    z-index: 20;
    /* border: 1px solid green !important; */
  }
  .sideBar.collapse {
    /* border: 1px solid red !important; */
    width: 0px !important;
    min-width: 0px !important;
    max-width: 0px !important;
  }
  .overlay {
    display: none;
    /* border: 1px solid yellow !important; */
  }
  .noOverlay {
    display: block;
    position: absolute;
    top: 0;
    z-index: -2 !important;
    /* margin-left: 246px; */
    width: 100vw;
    /* width: calc(100svw - 246px); */
    height: 100vh;
    /* color: red; */
    /* background-color: #ffffff79; */
    /* border: 1px solid blue !important; */
  }
}
