.heading {
  color: #82858f;
}
.lowercontent {
  color: #292c40;
  padding-left: 40px;
  font-family: 'Lato';
  width: 200px;
}
.lowercontent2 {
  color: #292c40;
  font-family: 'Lato';

  padding-left: 20px;
}
.cardLike {
  background-color: whitesmoke;
  width: 220px;
}
.cardLeft {
  background-color: whitesmoke;
  width: 220px;
  padding-left: auto;
  margin-left: auto;
}
.icon {
  padding-left: 116px;
}
.statData {
  margin-top: 8px;
}
.statData > div {
  margin-bottom: 5px;
}
.statData > div > span {
  display: inline-block;
  font-weight: 500;
}
.statData > div > span:first-child {
  display: inline-block;
  min-width: 90px;
}
.statData > div > span:nth-child(2) {
  display: inline-block;
  min-width: 20px;
}
