.addmodule1 .ant-input-number .ant-input-number-handler-wrap {
  opacity: 10 !important;
  display: flex;
}

.ant-switch {
  height: 17px;
  width: 40px;
}
.ant-switch .ant-switch-handle {
  top: 1px;
  width: 15px;
  height: 15px;
  margin-left: 2px;
}
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  inset-inline-start: 0;
  padding-inline: calc(44% - 8px - 4px);
  text-overflow: clip;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  inset-inline-start: 0;
  padding-inline: calc(44% - 8px - 4px);
  text-overflow: clip;
}
.ant-drawer-body {
  padding: 0px !important;
}
.ant-menu-item-active:hover,
.ant-menu-submenu-active:hover,
.ant-menu-submenu > div:hover {
  background-color: #b7c4dbb2 !important;
  color: black !important;
}
.ant-menu-item.ant-menu-item-selected {
  background-color: var(--tm-bgcolor2) !important;
  color: white !important;
}

.ant-menu-submenu-open.ant-menu-submenu-selected:has(.ant-menu-submenu-title) {
  background-color: #d4e3ff !important;
  color: #1b67b3 !important;
}
.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected > div {
  color: black !important;
}
.ant-menu-submenu-selected {
  background-color: #d4e3ff !important;
  /* color: red !important; */
}
.ant-dropdown-menu-item {
  cursor: default !important;
}
.ant-picker .ant-picker-input input {
  cursor: pointer !important;
}

/* modal footer button color  */
.custom-ok-button:hover {
  transition-duration: 400ms !important;
  background-color: var(--tm-bgcolor2) !important;
}
.custom-cancel-button:hover {
  transition-duration: 400ms !important;
  border: 1px solid var(--tm-bgcolor2) !important;
  color: var(--tm-bgcolor2) !important;
}
ul.ant-picker-ranges > li,
ul.ant-picker-ranges > li button {
  width: 100% !important;
}
.ant-select-tree-title {
  color: black !important;
}
.ant-select-tree-treenode-disabled {
  font-weight: bold !important;
}
.ant-select-tree-list-holder-inner
  > div:last-child.ant-select-tree-treenode-disabled:has(button)
  > span:nth-child(2) {
  display: none !important;
}
