.accordion-button::after {
  display: none;
}
.accordion-button {
  /* display:none; */
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.accordion-button:focus {
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0.125) !important;
}
.accordion-header
  > .accordion-button:not(.collapsed)
  > div:first-child
  > span:nth-child(3) {
  transform: rotate(-180deg) !important;
  display: inline-block;
  transition-duration: 500ms;
}
.accordion-header > .accordion-button > div:first-child > span:nth-child(3) {
  transform: rotate(0deg) !important;
  display: inline-block;
  transition-duration: 500ms;
}
.time-table td {
  height: 40px;
}
.time-table table > tbody > tr > td {
  background-color: #fff !important;
  border: 1px solid rgb(202, 202, 202) !important;
}
.tableDisable table tbody td {
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}
