.projectTrackingmaincard {
  height: 70px !important;
}
.projectTrackingCard {
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    rgb(174, 174, 180) 0%,
    rgb(241, 238, 232) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  color: #c1c1c1 !important ;
}
.borderBottom {
  border-bottom: 1px solid #bdcadd;
}
.borderside {
  border-right: 1px solid #bdcadd;
}
