.textarea {
  height: 100px;
}
.arrow-icon {
  cursor: pointer;
  margin: 3px;
  /* align-items: center;
    display: flex; */
  color: #5f6271;
  /* border:1px solid #D1D1D1; */
}
.add-user {
  color: #21253c;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: bold;
}
.maystyle {
  padding-left: 16%;
}
.StartDate {
  color: #82858f;
}
.viewTable > tbody > tr > td {
  height: 45px !important;
  vertical-align: top;
}
.viewTable > tbody > tr > td:nth-child(3) {
  font-weight: 600;
}
.viewTable > tbody > tr > td:nth-child(3) {
  white-space: normal !important;
}
.logimg {
  width: 35px;
}
.forpara {
  color: #383c51;
  font-weight: normal;
}
.lightcolor {
  color: #82858f;
}
.cardstyle {
  height: 490px;
  /* width: 300px; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow-x: hidden;
}

.largepara {
  padding: 1px 25px 0px 0px;
}

/* new Styles */
.leaveManage > div:nth-child(2) > div > div {
  min-height: 60px;
  min-width: 200px;
  /* border: 1px solid red !important;  */
}
.vertical {
  border-left: 2px dotted #82858f;
  margin-left: 20px;
  height: 100%;
}
/* .CommmentImage {
  height: 40px;
  width: 40px;
} */
@media screen and (max-width: 767px) {
  .forapplyLeave {
    width: 100% !important;
  }
}
.overflowfosmall {
  overflow-y: auto;
}
@media screen and (min-width: 768px) {
  .LeavemanagementTable {
    height: 49px !important;
    min-height: 490px !important;
  }
  .LeavemanagementTable1 {
    height: 490px !important;
    min-height: 490px !important;
  }
  .scrollforReason {
    overflow-y: auto;
    max-height: 100px;
  }
}
