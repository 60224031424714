.nav-tabs{
    border-bottom: none !important;
}
.nav-tabs .nav-link{
    border-top-left-radius:none !important;
    border-top-right-radius:none !important;
    border: 1px solid var(--tm-bgcolor3) !important;
    color: var(--tm-bgcolor1) !important;
    background-color:#fff !important;
    border-radius:6px ;
}
.nav-tabs .nav-link.active{
    background-color:var(--tm-bgcolor1) !important;
    color:var(--tm-bgcolor3) !important;
}

.mygroup .input-group-text,
.mygroup  .form-control{
    border-radius:0px !important;
    background: #fff !important;    
}
.mygroup .form-control{
    border-left: none !important;
}
.mygroup .form-control:focus{
    border-color:#dee2e6 !important;
    box-shadow: none !important;
}
.mygroup .input-group-text{
    border-right: none !important;

}