/*  Header Styles */

.menuContainer {
  display: flex;
  align-items: center;
}

/* UserInfo.js Styles */
.profilePopup {
  min-width: 280px !important;
}
/* .profilePopup > li {
  min-height: 50px;
} */
.dropdown {
  border: 3px solid #fff;
  height: 45px;
  width: 45px;
  cursor: pointer;
  border-radius: 25px;
  outline: 1px solid var(--tm-bgcolor2);
}

.image {
  height: 45px;
  width: 45px;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}
.txtContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}
.containerTitle {
  margin-bottom: 0;
  font-size: 14px;
}

/* EventHandlers.js Styles */

.eventStyle {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.eventTitle {
  margin-bottom: 0;
  font-size: 16px;
}

/* .notification bar styles */

.notificationBadge {
  background-color: var(--tm-bgcolor11);
  border: 1px solid var(--tm-bgcolor8);
}

.notificationBadgeRead {
  background-color: #f9f9f9;
  border: 1px solid var(--tm-bgcolor8);
}
.markRead {
  max-height: 30px !important;
}
.notifyBdy {
  height: calc(95% - 30px) !important;
}
.notifyBdy section {
  height: 100%;
  display: flex;
  place-items: center;
  place-content: center;
}
.dropdown1 {
  border: 3px solid #fff;
  height: 65px;
  width: 65px;
  cursor: pointer;
  border-radius: 55px;
  outline: 1px solid var(--tm-bgcolor2);
}
.dropdown2 {
  border: 3px solid #fff;
  height: 65px;
  width: 65px;
  cursor: pointer;
  border-radius: 55px;
  outline: 1px solid var(--tm-bgcolor2);
}
