/* DashColorCards */
.dashboard {
  color: var(--tm-bgcolor5) !important;
}
.dashCardParent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/*DashEmpList*/

.dashHorizantal {
  border-top: 1px solid #00000014;
}
.dashtablealign {
  color: #163658;
  font-weight: bold;
}
.tableHeadcolor {
  color: #a0a1a8 !important;
}
.tabletd4color {
  color: #82bca4;
}
.tabletd3color {
  color: #a886de;
}
.tabletd2color {
  color: #f1c682;
}
.tabletd1color {
  color: #a0a1a8;
}
.tablebody {
  overflow-x: scroll;
  height: 6px !important;
}
.tableWrapper {
  overflow-y: auto;
  overflow-x: hidden;
}
.tableHeadfixed {
  background-color: white !important;
  position: sticky !important;
  top: 0;
}
/*cardLocked*/
.cardLocked {
  background-color: red !important;
  border: 10px solid white !important;
  width: 100%;
}

.CardLockedno {
  background-color: white;
  width: 50px;
  height: 25px;
}
.Cardfont {
  color: #163658;
}
.dashnamesize {
  color: #163658;
}
.dashDynamic1 {
  background-color: rgba(188, 208, 243);
}
.dashDynamic2 {
  background-color: rgba(216, 230, 255);
}
/* .dashTakenBg {
  background-image: url('https://media.geeksforgeeks.org/wp-content/uploads/20231218222854/1.png');
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-font-smoothing: antialiased;
} */
.dashTakenBg > div {
  max-height: 190px;
  overflow: auto;
}
.dashTakenBg2 {
  font-weight: bolder;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  max-height: 200px;
  overflow-y: auto;
}
.dashCardCol {
  color: var(--tm-bgcolor5);
}
.dashCardCol > div:nth-child(1) > div,
.dashCardCol > div:nth-child(4) > div {
  background-color: #e8f4fe;
}
.dashCardCol > div:nth-child(2) > div,
.dashCardCol > div:nth-child(3) > div {
  background-color: #f9fffd;
}
.dashCardCol > div:nth-child(1) > div > span,
.dashCardCol > div:nth-child(4) > div > span {
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  background-color: var(--tm-bgcolor2);
}
.dashCardCol > div:nth-child(2) > div > span,
.dashCardCol > div:nth-child(3) > div > span {
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  background-color: #a8c7ba;
}

.dashCardParent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--tm-bgcolor5);
  font-weight: bold;
}

/* TimesheetStatus */
.statusCardColor > p:nth-child(1) {
  background-color: #a7e1ca;
  color: rgb(0, 128, 38);
}
.statusCardColor > p:nth-child(3) {
  background-color: #d8beff;
  color: #a886de;
}
.statusCardColor > p:nth-child(2) {
  background-color: #fed79a;
  color: #f8a013;
}
.statusCardColor > p:nth-child(1) > span:nth-child(2) {
  border: 1px solid rgb(0, 128, 38);
}
.statusCardColor > p:nth-child(2) > span:nth-child(2) {
  border: 1px solid #a886de;
}
.statusCardColor > p:nth-child(3) > span:nth-child(2) {
  border: 1px solid #f8a013;
}
.statusCardColor > p > span:nth-child(2) {
  background-color: #fff;
}

.takenCard {
  height: 37px;
  width: 40px;
}
.scrollbar {
  overflow-y: auto;
  height: 160px;
}
.lockedListImg {
  height: 50px;
  width: 50px;
  object-fit: contain;
  border: 3px solid transparent;
  outline: 1px solid var(--tm-bgcolor2);
}
@media screen and (max-width: 768px) {
  .monthYearres {
    width: 100% !important;
  }
  .permissionres {
    width: 100% !important;
  }
  .tableWrapper {
    overflow-y: auto;

    overflow-x: auto;
  }
  .Total {
    position: absolute;
    bottom: 25%;
    right: 44%;
  }
  .position {
    width: 100% !important;
  }
  .statusCardColor {
    width: 100% !important;
  }
  /* .employeetableres {
    position: relative;
    top: 80px;
  } */
  .EmployeeListshi {
    min-height: 440px;
    height: 440px;
  }
  .dashCardParent {
    min-height: 100px;
  }
}
@media screen and (min-width: 1024px) {
  .monthYearres {
    width: 75% !important;
  }
  .permissionres {
    width: 50% !important;
  }
  .firstdiv {
    min-height: 482px;
    height: 482px;
  }
  .DashColorCardshi {
    height: 35%;
  }
  .EmployeeReportshi {
    height: 65%;
  }
  .EmployeeListshi {
    min-height: 468px;
    height: 468px;
  }
  .Total {
    position: absolute;
    bottom: 20%;
    right: 44%;
  }
  .StatusHight {
    height: 88% !important;
  }
  .position {
    width: 50% !important;
  }
  .statusCardColor {
    width: 50% !important;
  }
}

.cardAlignment {
  width: 70% !important;
  display: flex !important;
  /* border: 1px solid cyan; */
}
.iconAlignment {
  width: 16% !important;
  /* border: 1px solid purple; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.textoverflowfortext {
  width: 96% !important;
}
